<template>
	<div class="home">
		<v-card>
			<v-container fluid>
			</v-container>
		</v-card>
	</div>
</template>
<script>
import Breadcrumbs from "@components/Breadcrumbs";
import { mapState } from "vuex";

export default {
    components: { Breadcrumbs },
    mixins: [],
    data () {
        return {
            selectedBroker: "",
            selectedBrokerId: -1
        };
    },
    computed: {
        ...mapState(["brokerMap"])
    },
    methods: {
    },
    mounted () {
        const data = JSON.parse(localStorage.getItem("bp"));
        const brokerArr = data.broker;
        if (brokerArr.length > 1) {
            this.brokers = brokerArr;
            this.selectedBrokerId = 1;
            this.selectedBroker = this.brokers[0].broker;
        }

        const brokerId = data.broker[0].broker_id;
        const broker = data.broker[0].broker;
        this.selectedBrokerId = brokerId;
        this.selectedBroker = broker;
    }
};
</script>
